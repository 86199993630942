import React from 'react';
import Modal from 'react-modal';

export default function ConfirmPurchaseModal({modalIsOpen, closeModal, onclick}) {
    let customStyles = {
        overlay: {
            backgroundColor: '#0007'
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        },
    };

    if (window.innerWidth > 900) {   
        customStyles.content.width = '50%';
    }else{     
        customStyles.content.width = '95%';
    }

    return (
        <Modal style={customStyles} isOpen={modalIsOpen} onRequestClose={closeModal}>
            <div className='modal-border'>
                <h2>선택</h2>
                <hr className='line'></hr>
                <p>이 상품을 정말 구매하시겠습니까?</p>
                <button onClick={onclick}>네</button>
                <button onClick={closeModal}>아니요</button>
            </div>
        </Modal>
    );
}

