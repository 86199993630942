import React from 'react';
import styles from '../../style/orderinfo.module.css';
import {useOrderContext} from '../../context/OrderContext';

function DeliverInfo() {

    // const {receiver, phone, address, memo, itemCode, option} = useOrderContext();
    const {isLoading, data} = useOrderContext();

    return (
            <section>
                <h3 className={styles.h3}>주소지 정보</h3>
                <table className='order-info-table text-left'>
                    <tbody>
                        <tr>
                            <th>받으시는 분</th>
                            <td>{data.recvr_name}</td>
                        </tr>
                        <tr>
                            <th>전화번호</th>
                            <td>{data.recvr_phone}</td>
                        </tr>
                        {data.code == 20 ? null : (    
                            <tr>
                                <th>주소</th>
                                <td>{'(' + data.recvr_post + ")" + data.recvr_adres1 + data.recvr_adres2}</td>
                            </tr>
                        )}
                        {data.code != 30 ? null : (    
                            <tr>
                                <th>필수옵션</th>
                                <td>{data.option}</td>
                            </tr>
                        )}
                        <tr>
                            <th>배송요청사항</th>
                            <td>{data.deliver_txt}</td>
                        </tr>
                    </tbody>
                </table>
            </section>
    );
}

export default DeliverInfo;