import {useQuery} from '@tanstack/react-query';

function useMall({service}) {

    const {isLoading, data:mall_data} = useQuery({
      queryKey : ['mall'], 
      queryFn : async () => {
        let res = await service.getMall()
        return res.data;
      },
      staleTime: Infinity
    })

    return {isLoading, mall_data};
}

export default useMall;