import React, {forwardRef} from 'react';
import styles from '../../style/productdetail.module.css'

export const ExChangeInfo = forwardRef((props, ref) => {


    return (
        <section ref={ref} className='exchange-info'>
            <div className={styles.table_container}>
                <table className={styles.table}>
                    <caption className={styles.caption}>교환/반품 정보</caption>
                    <tbody>
                        <tr className={styles.tr}>
                            <th className={styles.th}>교환/반품 비용</th>
                            <td className={styles.td}>ㆍ단순변심으로 인한 교환 및 반품은 8,000원의 배송비를 고객님께서 부담하셔야 합니다.</td>
                        </tr>
                        <tr className={styles.tr}>
                            <th className={styles.th}>교환/반품 신청 기준일</th>
                            <td className={styles.td}>
                                ㆍ단순변심으로 인한 상품의 교환/반품은 제품 수령 후 7일 이내까지만 신청이 가능합니다.
                                ㆍ받으신 상품이 표시나 광고된 내용과 다르거나 제품 불량인 경우 상품을 받으신 날부터 3개월 이내까지만 가능하며, 위 내용을 알게 되신 날로부터 30일 이내까지 신청이 가능합니다.</td>
                        </tr>
                        <tr>
                            <th className={styles.th}>교환/반품 주의사항</th>
                            <td className={styles.td}>
                                ㆍ상품 배송 후 7일이 경과되었거나 상품이 훼손되어 가치가 현저히 감소했을 경우에는 반품 및 교환이 불가능합니다.
                                ㆍ상품 설명 내 취소 / 반품 / 교환에 대한 안내 사항이 있을 경우 해당 안내를 기준으로 적용됩니다.</td>
                        </tr>
                    </tbody>
                </table>
            </div>
        </section>
    );
}) 
export default ExChangeInfo;
