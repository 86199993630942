export default class Service{

    constructor(http, tokenStorage){
        this.tokenStorage = tokenStorage
        this.http = http
    }

    async getMall(){
        return this.http.axios('/login', {
            method : 'GET',
        })
    }

    async login(account, password, path){
        return this.http.axios('/post/login', {
            method : 'POST',
            data : {
                account,
                password,
                path
            }
        });
    }

    async logout(){
        return await this.http.axios('/logout', {
            method : 'POST'
        });
    }

    async getAuth(){
        return this.http.axios('/auth', {
            method : 'GET',
        })
    }

    async getProducts(){
        return this.http.axios('/products', {
            method : 'GET',
        })
    }

    async getProduct(id){
        return this.http.axios(`/detail/${id}`, {
            method : 'GET',
        })
    }

    async purchase(inputs, id){
        // console.log(inputs);
        // console.log(id);
        return this.http.axios(`/buy/point/${id}`, {
            method : 'POST',
            data : {
                recvr_name   : inputs.receiver  ,
                recvr_phone  : inputs.phone , 
                recvr_post   : inputs.post  , 
                recvr_adres1 : inputs.address, 
                recvr_adres2 : inputs.address_detail, 
                deliver_txt  : inputs.memo ,
                option       : inputs.option      
            }
        })
    }

    async getConfirmOrder(){
        return this.http.axios(`/order/confirmed` ,{
            method : 'GET',
        })
    }

    async getOrderInfo(){
        return this.http.axios(`/mypage/order` ,{
            method : 'GET',
        })
    }

    async checkOrderState(){
        return this.http.axios('/mypage' ,{
            method : 'GET',
        })
    }

    async cancelOrder(orderId, amount){
        return this.http.axios(`/order/cancel/${orderId}` ,{
            method : 'POST',
            data: {
                amount
            }
        })
    }

    async updateDeliverState(orderId, status){
        return this.http.axios(`/order/${orderId}/status/${status}` ,{
            method : 'PUT'
        })
    }

    async csrfToken(){
        const resp = await this.http.axios('/csrf-token', {
            method : 'GET'
        });
        return resp.data.csrfToken;
    }
}