import React, { useState } from 'react';
import styles from '../style/order.module.css'
import OrderInput from '../components/order/OrderInput';
import OrderProduct from '../components/order/OrderProduct';
import ConfirmPurchaseModal from '../components/modal/ConfirmPurchaseModal';
import CheckValidModal from '../components/modal/CheckValidModal';
import CheckPointModal from '../components/modal/CheckPointModal';
import { useMallContext } from '../context/MallContext';
import { useNavigate, useOutletContext, useParams } from 'react-router-dom';
import useProduct from '../hooks/useProduct';
import Loading from '../components/Loading';

export default function Order() {

    let { id } = useParams();
    const {service} = useOutletContext();
    const [modalOpen1, setModalOpen1] = useState(false); // 구매확정 모달창
    const [modalOpen2, setModalOpen2] = useState(false); // 유효성 검사 모달창
    const [modalOpen3, setModalOpen3] = useState(false); // 포인트 부족 모달창
    const [isBtnDisable, setIsBtnDisable] = useState(false); // 더블클릭 방지용

    const [inputs, setInputs] = useState({})
    const [loading, setLoading] = useState(false);
    const { mall_data } = useMallContext();

    // const {name, image, itemCode} = useProduct({});
    const {isLoading, error, status, product_data, purchase} = useProduct();

    const navigate = useNavigate();

    const setCustomInputs = (inputs) =>{
        setInputs(inputs);
    }

    const checkInputValid = () => {
        // console.log(id);
        let receiver = inputs.receiver;
        let recvr_phone = inputs.phone;
        let recvr_post = inputs.post;
        let recvr_adres1 = inputs.address;
        let recvr_adres2 = inputs.address_detail;
        let option = inputs.option;

        if(receiver == '' ){ return false; }
        if(recvr_phone == '' ){ return false; }
        let itemCode = product_data.data_item.item_code;
        if(itemCode != 20){
            if(recvr_adres1 == '' ){ return false; }
            if(recvr_post == '' ){ return false; }
            if(recvr_adres2 == '' ){ return false; }
        }
        if(itemCode == 30){
            if(option == '' ){ return false; }
        }
        return true;
    } 

    const checkPurchase = () => {
        if(checkInputValid() === false){     // 유효성 검사 실시
            return setModalOpen2(true);      // 입력하지 않은 정보 확인
        }
        setModalOpen1(true);                 // 구매확정 모달 open
    };

    const closeModal = () => {
        setModalOpen1(false);
        setModalOpen2(false);
        setModalOpen3(false);
        setIsBtnDisable(false); // 중복클릭 해제
    };

    const handelPurchase = () =>{
        setLoading(true);
        if(isBtnDisable === true){
            return false;
        }
        setIsBtnDisable(true);
        purchase.mutate(inputs);

    }
    
    if(isLoading == true){ return (<Loading/>) }

    return (
        <div className={`wrapper ${styles.container}`}>
            <OrderInput setCustomInputs = {setCustomInputs}/> {/* 주문 input 데이터 관리 */}
            <OrderProduct checkPurchase={checkPurchase}/>     {/* 주문 상품 정보 */}
            <ConfirmPurchaseModal modalIsOpen={modalOpen1} closeModal={closeModal} onclick={handelPurchase}/>
            <CheckValidModal modalIsOpen={modalOpen2} closeModal={closeModal}/>
            <CheckPointModal modalIsOpen={modalOpen3} closeModal={closeModal}/>
        </div>
    );
}

