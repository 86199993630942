import React, {useState} from 'react';

function useOrderInput() {

    const [inputs, setInputs] = useState({
        post : '',
        address : '',
        address_detail : '',
        receiver : '',
        phone : '',
        option : '',
        memo : ''
    });

    const getAddressInputs = (post, address) =>{
        setInputs({
            ...inputs,
            ['post'] : post,
            ['address'] : address
        })
    }

    const onChange = (e)=>{
        const {name, value} = e.target;
        setInputs({
            ...inputs,
            [name]:value
        });
    }

    return [inputs, getAddressInputs, onChange];
}

export default useOrderInput;