import React, {useEffect, useState } from 'react';
import { useOutletContext, useParams, useNavigate } from 'react-router-dom';
import styles from '../style/orderconfirm.module.css'
import { useMallContext } from '../context/MallContext';

export default function OrderConfirm() {

    const { mall_data } = useMallContext();
    const [image, setImage] = useState();
    const [name, setName] = useState();
    const [receiver, setReceiver] = useState();
    const [phone, setPhone] = useState();
    const [address, setAddress] = useState();
    const [memo, setMemo] = useState();
    const [option, setOption] = useState();
    const [itemCode, setItemCode] = useState(10);
    const {service} = useOutletContext();

    const navigate = useNavigate();

    useEffect(()=>{

        service.getConfirmOrder().then((response)=>{
            // console.log(response.data)
            setItemCode(response.data.data_item.item_code);
            setImage(response.data.data_item.thm_path)
            setName(response.data.data_item.item_name)
            let address = '(' + response.data.data_item.recvr_post + ")" + response.data.data_item.recvr_adres1 + response.data.data_item.recvr_adres2
            setAddress(address)
            setReceiver(response.data.data_item.recvr_name)
            setPhone(response.data.data_item.recvr_phone)
            setMemo(response.data.data_item.deliver_txt)
            setOption(response.data.data_item.option)
        })

    },[])

    return (
        <div className='wrapper'>
            <h2 className={styles.h2}>주문이 완료되었습니다.</h2>
            <div className={styles.container}>
                <section className={styles.section1}>
                    <img className={styles.img} src={image} alt="thm-image" />
                    <span className={styles.name}>{name}</span>
                </section>
                <section className={styles.section2}>
                    <span className={styles.title}>배송지 정보</span>
                    <div className={styles.row}>
                        <span className={styles.label}>받으시는분</span>
                        <span className={styles.value}>{receiver}</span>
                    </div>
                    <div className={styles.row}>
                        <span className={styles.label}>전화번호</span>
                        <span className={styles.value}>{phone}</span>
                    </div>
                    {itemCode == 20 ? null : (
                            <div className={styles.row}>
                                <span className={styles.label}>주소</span>
                                <span className={styles.value}>{address}</span>
                            </div>
                    )}
                    {itemCode != 30 ? null : (
                            <div className={styles.row}>
                                <span className={styles.label}>필수옵션</span>
                                <span className={styles.value}>{option}</span>
                            </div>
                    )}
                    <div className={styles.row}>
                        <span className={styles.label}>요청사항</span>
                        <span className={styles.value}>{memo}</span>
                    </div>
                </section>
            </div>

            <div className={styles.button_container}>
                <button className={styles.button1} onClick={() => navigate(`${mall_data.path}/order/info`) }>주문내역 확인</button>
                <button className={styles.button2} onClick={() => navigate(`${mall_data.path}/list`) }>목록으로 돌아가기</button>
            </div>
        </div>
    );
}

