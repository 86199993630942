import React, {createContext, useState, useEffect, useMemo, useContext} from 'react';
import { Navigate, useNavigate, useOutletContext, useParams } from 'react-router-dom';
import { useMallContext } from '../context/MallContext';
import { useAuthContext } from '../context/AuthContext';
import DeliverService from '../service/deliver';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';

let deliver_service = new DeliverService();
const OrderContext = createContext();

export default function OrderContextProvider({children}) {
    const queryClient = useQueryClient();

    const {service} = useOutletContext();
    const {mall_data} = useMallContext();
    const {setUser} = useAuthContext();
    const navigate = useNavigate();

    const {isLoading, error, data, status} = useQuery({
        queryKey : ['order'],
        queryFn : async () => {
            let res = await service.getOrderInfo();
            if(res.data.code != 20){
              let result = await checkDeliver(res.data.data_item);
            }
            return res.data.data_item;
          },
          staleTime: 1000 * 60 * 30 // 30분 단위
    })

    const cancelPurchase = useMutation({
        mutationFn : (param) => service.cancelOrder(param.orderId, param.amount),
        onSuccess: (response) => {
            if(response.status == 200){
                setUser(response.data.user);
                queryClient.invalidateQueries(['product']);
                alert('결제가 취소되었습니다.');
                navigate(`${mall_data.path}/list`);
            }
            else if(response.status == 201){
                console.log('이미 환불된 건입니다.');
            }
        },
    })

    const checkDeliver = async (data) => {
        let invoiceNo = data.invoice_no;
        invoiceNo = invoiceNo.replace(/-/gi, '');
        let deliverKind = data.deliver_compny;

        if (invoiceNo == "null" || deliverKind == "null" || invoiceNo.length == 0 || deliverKind.length == 0) {
            return data.deliver = false;
        }

        data.deliver = true;;
        let code = data.code;
        
        return deliver_service.getDeliver(deliverKind, invoiceNo, code).then(async (response)=>{
            // console.log(response);
            const progress = response.data.progresses;
            if (progress.length == 0) { 
                return data.deliver = false;;
            }
            let total_inputText = '';
            for (let i = progress.length - 1; i >= 0; --i) {
                const process = progress[i];
                const dateTime = new Date(process.time);
                let dateText = dateTime.getFullYear() + "년 " + (dateTime.getMonth() + 1) + "월 " + dateTime.getDate() + "일 ";
                if (dateTime.getHours() < 10) {
                    dateText += "0";
                }
                dateText += dateTime.getHours() + "시 " + dateTime.getMinutes() + "분";
                let inputText = ' \
                    <tr> \
                        <td>' + process.status.text + '</td> \
                        <td>' + process.location.name + '</td> \
                        <td>' + process.description + '</td> \
                    </tr> \
                '
                total_inputText = total_inputText + inputText;
            }
            data.trackingInfo = total_inputText;
            let result = await updateDeliver(data, response.data);
        })
        .catch((error)=>{ console.log(error);})
    }

    const updateDeliver = async (data, result) => {

        let status = result.state.id;
        // console.log(data);
        // console.log(status);
        if (data.code < 20 && data.code > 30) {
            return false;
        }

        let orderStatus = 0;
        if (data.code < 23 && status == "delivered") {
            // 업데이트 바꾸기
            orderStatus = 23;
        } else if (data.code < 22 && status == "out_for_delivery") {
            // 업데이트 바꾸기 (배송중으로)
            orderStatus = 22;
        } else {
            return false;
        }

        // 배송상태가 이미 21, 22, 23인 것은 변경되지 않음(접수건에 대해서만 상태값 변함)
        return service.updateDeliverState(data.order_id, orderStatus).then((response)=>{
            // console.log(response);
            // setOrderCode(orderStatus);
            data.code = orderStatus
            if (response == true) {
                switch (orderStatus) {
                    case 23 : data.order_status_name = '배송완료'; break;
                    case 22 : data.order_status_name = '배송중'; break; 
                    case 21 : data.order_status_name = '배송대기'; break;
                }
            }
        })
        .catch((error)=>{ console.log('배송상태 값 업데이트 실패'); console.log(error);})
    }

    const context = useMemo(
        () => ({
            isLoading, error, data, status, cancelPurchase
        }),
        [isLoading, error, data, status, cancelPurchase ]
      );

    return (
        <OrderContext.Provider value={context}>
            {children}
        </OrderContext.Provider>
    );
}

export function useOrderContext() {
    return useContext(OrderContext);
  }
