import React from 'react';
import styles from '../../style/order.module.css'
import Button from '../Button'
import useProduct from '../../hooks/useProduct';
import Loading from '../Loading';

export default function OrderProduct({checkPurchase}) {

    const {product_data, isLoading} = useProduct();

    if(isLoading==true)
    return <Loading/>

    return (
        <section className={styles.right}>
            <div className={styles.top}>주문 정보</div>
            <div className={styles.item_info}>
                <div className={styles.img_box}><img src={product_data.data_item.thm_path} alt="thm-image" /></div>
                <div className={styles.info_box}>
                    <h4>{product_data.data_item.name}</h4>
                    <div className={styles.column}>
                        <span>배 송 비 : 무료배송</span>
                    </div>
                </div>
            </div>
            <Button text={'결제하기'} onclick={checkPurchase}/>
        </section>
    );
}

