import React from 'react';
import styles from '../style/orderconfirm.module.css'
import { useNavigate, useOutletContext } from 'react-router-dom';
import { useMallContext } from '../context/MallContext';

export default function Mypage () {
    
    const navigate = useNavigate();
    const { mall_data } = useMallContext();

    return (
        <div className={`wrapper ${styles.mypage}`}>
            <h2 className={styles.title2}>주문 내역이 없습니다.</h2>
            <div className={styles.line}></div>
            <p className={styles.p}>
                구매하신 상품을 찾을 수 없습니다.<br/>
                도움이 필요한 사항은 채널톡으로 문의해주시면 신속하게 처리해드리겠습니다.
            </p>
            <button className={styles.button1} onClick={() => navigate(`${mall_data.path}/list`) }>목록으로 돌아가기</button>
        </div>
    ); 
};

