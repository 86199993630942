import React from 'react';
import styles from '../../style/productdetail.module.css';
import Button from '../../components/Button';
import Loading from '../Loading';
import useProduct from '../../hooks/useProduct';
import { useMallContext } from '../../context/MallContext';
import { useAuthContext } from '../../context/AuthContext';
import { useNavigate, useParams} from 'react-router-dom';

const style = { fontWeight:'600'}

export default function ProductInfo({openModal}) {
    let { id } = useParams();
    const {mall_data} = useMallContext();
    const {user} = useAuthContext();

    let {isLoading, product_data} = useProduct();
    const navigate = useNavigate();

    const goToPurchase = () => {
        let point = user.total_amount;
        // console.log(point)
        // console.log(product_data.data_item.price_sell)

        if(point < product_data.data_item.price_sell){
            return openModal();
        }

        let item_id = id;
        navigate(`${mall_data.path}/order/${item_id}`)
    }
    

    if(isLoading == true){
        return (<Loading/>)
    }

    return (
        <div className={`wrapper ${styles.container}`}>
            <section className={styles.left} style={{marginBottom:'0px'}}>
                <img src={product_data.data_item.thm_path} alt="thumbnail"/>
            </section>
            <section className={styles.right} style={{marginBottom:'0px'}}>
                <h2>{product_data.data_item.item_name}</h2>
                <div className={styles.border}>
                    <div className='row'>
                        <span className={styles.label}>배송비</span>
                        <span className={styles.value}> 무료</span>
                    </div>
                </div>
                <div className={styles.bottom}>
                    <h3>{product_data.data_item.item_name}</h3>
                    <div className={styles.handler}>
                        <button disabled="" className="decrease" >-</button>
                        <span className="count">1</span>
                        <button disabled="" className="increase" >+</button>
                    </div>
                </div>
                <Button style={style} text={'구매하기'} onclick={goToPurchase}></Button>
            </section>
        </div>
    );
}

