import React, {useState} from 'react';
import popup from '../../images/popup.jpg'
import styles from '../../style/popup.module.css'


function Popup() {

    const [open, setOpen] = useState(true);

    const getCookie = (cookieName) => {
        cookieName = `${cookieName}=`;
        let cookieData = document.cookie;
      
        let cookieValue = "";
        let start = cookieData.indexOf(cookieName);
      
        if (start !== -1) {
          start += cookieName.length;
          let end = cookieData.indexOf(";", start);
          if (end === -1) end = cookieData.length;
          cookieValue = cookieData.substring(start, end);
        }
        
        return unescape(cookieValue);
    } 
    const closeModal = () => {
        setOpen(!open);
    }
    const closeTodayModal = ()=>{
        setCookie('popup', "done", 1);
        closeModal()
    }
    const setCookie = (name, value, expiredays) => {        
        let todayDate = new Date(); todayDate = new Date(parseInt(todayDate.getTime() / 86400000) * 86400000 + 54000000);

        if (todayDate > new Date()) {
            expiredays = expiredays - 1;
        }
  
        todayDate.setDate(todayDate.getDate() + expiredays);

        document.cookie = name + "=" + escape(value) + "; path=/; expires=" + todayDate.toGMTString() + ";";
    }

    if(getCookie('popup') == 'done') return null; 

    return (<>
        { 
            open ? 
            <div className={styles.modal}>
                <img className={styles.popup} src={popup} alt="popup_img" />
                <div className={styles.container}>
                    <button className={styles.today_close} onClick={closeTodayModal}>오늘 하루 동안 이 창을 열지 않음</button>
                    <button className={styles.btn_close} onClick={closeModal}>닫기</button>
                </div>
            </div> 
            : null
        }
        </>);
}

export default Popup;