import React,{useState, useEffect} from 'react';
import Modal from 'react-modal';


export default function CheckPointModal ({modalIsOpen, closeModal, onclick}) {
    
    let customStyles = {
        overlay: {
            backgroundColor: '#0007'
        },
        content: {
          top: '50%',
          left: '50%',
          right: 'auto',
          bottom: 'auto',
          marginRight: '-50%',
          transform: 'translate(-50%, -50%)',
        
        },
    };

    if (window.innerWidth > 900) {   
        customStyles.content.width = '50%'; 
    }else{     
        customStyles.content.width = '95%';
    }
    
    return (
        <Modal style={customStyles} isOpen={modalIsOpen} onRequestClose={closeModal}>
            <div className='modal-border'>
                <h2>안내</h2>
                <hr className='line'></hr>
                <p>잔여포인트가 부족합니다.</p>
                <button onClick={closeModal}>닫기</button>
            </div>
        </Modal>
    );
};


