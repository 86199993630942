import React, { useEffect, useState, useRef } from 'react';
import styles from '../style/productdetail.module.css'
import CheckPointModal from '../components/modal/CheckPointModal';
import ProductInfo from '../components/detail/ProductInfo';
import ExChangeInfo from '../components/detail/ExChangeInfo';
import useProduct from '../hooks/useProduct';


function ProductDetail() {

    let {product_data} = useProduct();
    const [modalOpen, setModalOpen] = useState(false);
    const content1Ref = useRef(null);
    const content2Ref = useRef(null);

    const openModal = () => {
        setModalOpen(true);
    };
    const closeModal = () => {
        setModalOpen(false);
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.scrollTo(0,0);
        return () => { window.removeEventListener('scroll', handleScroll);};
      }, []);

    const handleScroll = () => {
        const ele = document.querySelector('.image-info');
        const menu1 = document.querySelector(".menu1");
        const menu2 = document.querySelector(".menu2");

        const absPosition = window.scrollY + ele.getBoundingClientRect().bottom- 500;
        if(window.scrollY < absPosition || absPosition < 0){
            menu2.classList.remove(`${styles.active}`);
            menu1.classList.add(`${styles.active}`);
        }
        else {
            menu1.classList.remove(`${styles.active}`);
            menu2.classList.add(`${styles.active}`);
        }
    };
    const onContent1Click = (e) => {
      content1Ref.current?.scrollIntoView({ behavior: 'smooth' });
    }
    const onContent2Click = (e) => {
      content2Ref.current?.scrollIntoView({ behavior: 'smooth' });
    }

    return (
        <>
            <ProductInfo openModal={openModal}/> {/* 상품 정보 */}
            <div>
                <div className={styles.menu_form}>
                    <div className={`${styles.active} ${styles.menu} menu1`} onClick={onContent1Click}>상세설명</div>
                    <div className={ product_data && product_data.data_item.item_code == 20 ? `${styles.menu} menu2 hidden` : `${styles.menu} menu2` } onClick={onContent2Click}>배송/교환/반품 안내</div>
                    <div className={ styles.temp}></div>
                </div>
                <section className={`${styles.detail_img_section} image-info`}  ref={content1Ref}>
                    <div className={styles.detail_img} dangerouslySetInnerHTML={{__html: product_data && product_data.data_item.detail_info}}></div>
                </section>
                { product_data && product_data.data_item.item_code == 20 ? null : ( <ExChangeInfo ref={content2Ref}/> )}
            </div>
            <CheckPointModal modalIsOpen={modalOpen} closeModal={closeModal}/>
        </>
    );
}

export default ProductDetail;