import { useAuthContext } from '../context/AuthContext';
import { useNavigate, useParams, useOutletContext} from 'react-router-dom';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useMallContext } from '../context/MallContext';


export default function useProduct() {
    const queryClient = useQueryClient();

    let { id } = useParams();
    const {setUser} = useAuthContext();
    const {service} = useOutletContext();
    const {mall_data} = useMallContext();
    const navigate = useNavigate();

    const {isLoading, error, data: product_data, status} = useQuery({
        queryKey : ['product', id],
        queryFn : async () => {
            let res = await service.getProduct(id)
            setUser(res.data.user);
            return res.data;
          },
          staleTime: 1000 * 60 * 60 * 3
    })

    const purchase = useMutation({
        mutationFn : (inputs) => service.purchase(inputs, id),
        onSuccess: (response) => {
            queryClient.invalidateQueries(['product']);
            return navigate(`${mall_data.path}/order/confirm`);
          },
    }
    )

    return {isLoading, error, product_data, status, purchase};
}

