import React from 'react';
import { useNavigate, Link } from 'react-router-dom';
import styles from '../../style/product.module.css'

export default function Product({
    product:{item_id, thm_path, item_name},
    path
}) {

    const navigate = useNavigate();

    return (
        
        <li className={styles.container} onClick={() => {navigate(`${path}/product/${item_id}` );}}>
            <img className={styles.img} src={thm_path} />
            <h2 className={styles.name}>{item_name}</h2>
            {/* <span className={styles.price}>{price_sell}p</span> */}
        </li>
    );
}

