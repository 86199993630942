import React from 'react';
import spinner from '../images/spinner.svg'
import styles from '../style/loading.module.css'

export default function Loading() {
    return (
        <div className={styles.background}>
            <img src={spinner} className={styles.img}/>
        </div>
    );
}
