import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import styles from '../style/login.module.css'
import unlock from '../images/icons/icon_password.svg'
import Button from '../components/Button';
import Loading from '../components/Loading'
import { useAuthContext } from '../context/AuthContext';
import { useMallContext } from '../context/MallContext';
import user_image from '../images/icons/icon_user.svg';
import login_left from '../images/login_left.jpg';
import login_left_seolnal from '../images/login_left_seolnal.png';
import login_left_chuseok from '../images/login_left_chuseok.png';

const style = { marginTop:'20px'}

function Login({service, type, login_type}) {
    const { mall_data, isLoading } = useMallContext();
    const { Login } = useAuthContext();
    const navigate = useNavigate();

    useEffect(()=>{
        if(isLoading == false){
            service.getAuth().then((response)=>{ 
                if(response.status == 200 ){
                    if(window.confirm('로그인 정보가 남아있습니다. 해당 아이디로 로그인 하시겠습니까?')){return navigate(`${mall_data.path}/list`)}
                }
             })
            .catch((error)=>{ console.log(error);})
        }
    },[isLoading])

    const [inputs, setInputs] = useState({
        account : '',
        password : ''
    })

    const onChange = (e)=>{
        const name = e.target.name;
        const value = e.target.value;
        setInputs({
            ...inputs, 
            [name]:value
        });
    }

    const onSubmit = (event) =>{
        event.preventDefault();
        let {account, password} = inputs;
        Login(account, password, mall_data.path);
    }

    if(isLoading == true){
        return (<Loading/>)
    }
    return (
        <main className={styles.backgroundd}>
            <form className={styles.form} onSubmit={onSubmit}>
                <div className={styles.left}>
                    {
                        type=='seolnal' && <img src={login_left_seolnal} alt="login_left_seolnal" />
                    }
                    {
                        type=='birthday' && <img src={login_left} alt="login_left" />
                    }
                    {
                        type=='chuseok' && <img src={login_left_chuseok} alt="login_left_chuseok" />
                    }
                </div>
                <div className={styles.right}>
                    <img className={styles.logo} src={mall_data.data_mall.company_logo} alt="logo" />
                    <div className={styles.text}>
                        <h3 className={styles.span}>Welcome</h3>
                        <h3 className={styles.h3}>Login to your account</h3>
                    </div>
                    <div className={styles.input_box_container}>
                        <div className={styles.input_box}>
                            <img src={user_image} alt="id-icon" />
                            <input name='account' type="text" placeholder={type=='birthday' ? '생년월일+사번을 입력해주세요' : login_type=='c' ? '아이디를 입력해주세요' : '생년월일+사번을 입력해주세요'} onChange={onChange} />
                        </div>
                        <div className={styles.input_box}>
                            <img src={unlock} alt="password-icon" />
                            <input name='password' type="password" placeholder={type=='birthday' ? 'k+사번을 입력해주세요.' : login_type=='c' ? '비밀번호를 입력해주세요' : '생년월일을 입력해주세요'} onChange={onChange} />
                        </div>
                    </div>
                    <Button style={style} text={'접속하기'} onclick={onclick}></Button>
                </div>
            </form>
        </main>
    );
}

export default Login;