import { createContext, useContext, useEffect, useState, useMemo  } from 'react';
import { Helmet } from "react-helmet";
import Loading from '../components/Loading'
import useMall from '../hooks/useMall';
const MallContext = createContext();

export function MallContextProvider({ children, service, mallId}) {

  const {isLoading, mall_data} = useMall({service});

  const context = useMemo(
    () => ({
      mall_data, isLoading, mallId
    }),
    [mall_data, isLoading, mallId]
  );

  if(isLoading == true){
    return (<Loading/>)
  }

  return (
    <MallContext.Provider value={context}>
        <Helmet>
          <title>{mall_data.data_mall.company_name + mall_data.data_mall.mall_name}</title>
          <link rel="icon" href={mall_data.data_mall.favicon} /> 
        </Helmet>
        {children}
    </MallContext.Provider>
  );
}

export function useMallContext() {
  return useContext(MallContext);
}
