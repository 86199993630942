import React from 'react';
import Product from './Product';
import useProducts from '../../hooks/useProducts';
import Loading from '../Loading';
import styles from '../../style/home.module.css';
import { useMallContext } from '../../context/MallContext';

export default function Products() {

    const {mall_data} = useMallContext();
    const {isLoading, data}  = useProducts();

    if(isLoading == true){return (<Loading/>) }
    if(data.status == 203) {return (<div>현재 임시몰 기간이 아닙니다.</div>)}
    return (
         <section>   
            <ul className={`wrapper ${styles.itemlist}`}>
                {data.products &&
                    data.products.map((product)=>(
                        <Product key={product.item_id} product={product} path={mall_data.path}/>
                    ))
                }
            </ul>
        </section>
    );
}
