import axios from 'axios';

export default class Http {
    constructor(baseURL, mallPath, getCsrfToken){
        this.baseURL = baseURL;
        this.mallPath = mallPath;
        this.getCsrfToken = getCsrfToken;
    }

    async axios(url, options) {
        const res = await axios({
            ...options,
            url : `${this.baseURL}${this.mallPath}${url}`,
            withCredentials : true,
            headers :{
                'Content-Type': 'application/json',
                'headen-csrf-token':this.getCsrfToken()
            }
        });
        
        let data;

        try{
            data = await res;
        } catch(error){
            console.error(error);
        }

        if (res.status > 299 || res.status < 200) {
            const message =
              data && data.message ? data.message : 'Something went wrong! 🤪';
            throw new Error(message);
        }
        
        return data;
    }
}