import React, { useEffect, useState } from 'react';
import styles from '../../style/order.module.css';
import DaumAddress from '../../components/DaumAddress';
import useOrderInput from '../../hooks/useOrderInput';
import useProduct from '../../hooks/useProduct';

export default function OrderInput({setCustomInputs}) {

    const [inputs, getAddressInputs, onChange] = useOrderInput({});
    let {isLoading, product_data} = useProduct();
    
    useEffect(()=>{
        setCustomInputs(inputs);
    }, [inputs])

    return (
        <section className={styles.left}>
            {product_data && product_data.data_item.item_code == 20 ? (
                <div className={styles.box}>
                    <h3 className={styles.label}>모바일쿠폰</h3>
                </div>
            ) : (
                <div className={styles.box}>
                    <h3 className={styles.label}>받으시는 분</h3>
                    <DaumAddress getAddressInputs = {getAddressInputs}/>
                    <input name='address_detail' className={styles.input} type="text" placeholder="(상세주소)" onChange={onChange}/>
                </div>
            )}
            <div className={styles.box}>
                <span className={styles.span}>수취인</span>
                <input name='receiver' className={styles.input} type="text" onChange={onChange}/>
            </div>
            <div className={styles.box}>
                <span className={styles.span}>연락처</span>
                <input name='phone' className={styles.input} type="number" placeholder="-없이 숫자만 입력해주세요." onChange={onChange}/>
            </div>
            {product_data && product_data.data_item.item_code == 30 ? (
                 <div className={styles.box}>
                    <span className={styles.span}>*필수옵션</span>
                    <input name='option' className={styles.input} type="text" placeholder="색상, 카테고리 등 필수 옵션사항을 선택해주세요." onChange={onChange}/>
                </div>) : null
            }
           
            <div>
                <span className={styles.span}>요청사항</span>
                <textarea name='memo' className={styles.textarea} type="number" placeholder="요청사항을 입력해주세요.(최대 300자까지 입력가능합니다.)" onChange={onChange}/>
            </div>
        </section>
    );
}

