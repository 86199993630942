import React, { useEffect, memo, useState } from 'react';
import { useAuthContext } from '../context/AuthContext';
import { useMallContext } from '../context/MallContext';
import { Navigate } from 'react-router-dom';
import Loading from '../components/Loading'

 const ProtectedRoute = memo(({ children, service}) => {
    const { user, setUser } = useAuthContext();
    const { mall_data, mallId } = useMallContext();
    const [loading , setLoading] = useState(true);
    useEffect(()=>{
        // console.log('useEffect in ProtectedRoute');
        service.getAuth().then((response)=>{ setUser(response.data); })
        .catch((error)=>{ console.log(error);})
        .finally(() => { setLoading(false); })
    },[])


    if(loading == true){ return (<Loading/>) }

    return (
        user && user.mall_id == mallId ? children : <Navigate to={`${mall_data.path}/login`} />
    )

});

export default ProtectedRoute;