import React from 'react';
import styles from '../style/button.module.css'

export default function Button({text, onclick, style}) {
    return (
        <button style={style} className={styles.button} onClick={onclick}>
            {text}
        </button>
    );
}

