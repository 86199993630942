import React, { useState } from 'react';
import DaumPostcodeEmbed from 'react-daum-postcode';
import styles from '../style/order.module.css'

const DaumAddress = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [address, setAddress] = useState('');
  // 열기
  const openPostCode = () => {
    setIsOpen(true)
  }   
  // 닫기
  const closePostCode = () => {
    setIsOpen(false)
  }

  const handleComplete = (data) => {
    let finalAddress = ''
    let fullAddress = data.address;
    let extraAddress = '';

    if (data.addressType === 'R') {
      if (data.bname !== '') {
        extraAddress += data.bname;
      }
      if (data.buildingName !== '') {
        extraAddress += extraAddress !== '' ? `, ${data.buildingName}` : data.buildingName;
      }
      fullAddress += extraAddress !== '' ? ` (${extraAddress})` : '';
    }
    finalAddress = data.zonecode + ' ' + fullAddress;
    // console.log(finalAddress); // e.g. '서울 성동구 왕십리로2길 20 (성수동1가)'
    // console.log(data);
    setAddress(finalAddress);
    setIsOpen(false);
    props.getAddressInputs(data.zonecode, fullAddress);
  };
  const onChange = (e) => {
    const {name, value} = e.target;
    props.setInputs({
      ...props.inputs,
      [name]:value
    })
  }
  return (
    <div>
      { isOpen === true ? (
              <div className={`${styles.daum_container} ${styles.box}`}>
                <img src="https://t1.daumcdn.net/postcode/resource/images/close.png" className={styles.close_img} onClick={closePostCode} alt="접기 버튼"/>
                <DaumPostcodeEmbed onComplete={handleComplete}/>
              </div> ) : ( 
              <input name='address' className={`${styles.input} ${styles.background}`} type="text" placeholder='지번, 도로명, 건물명으로 검색' onClick={openPostCode} value={address} onChange={onChange}/> 
              )
      }
    </div>
  )
};

export default DaumAddress;