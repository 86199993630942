import React from 'react';
import { Outlet, useOutletContext } from 'react-router-dom';
import ProtectedRoute from './pages/ProtectedRoute';
import Navbar from './components/Navbar';
import Footer from './components/Footer';


function Layout() {
  const {service, path} = useOutletContext();

  return (
    <>
      <ProtectedRoute service={service}>
        <Navbar path={path} service={service}/>
        <Outlet context={{service}}/>
        <Footer/>
      </ProtectedRoute>
    </>
  );
}

export default Layout;
