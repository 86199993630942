import React from 'react';
import styles from '../../style/orderinfo.module.css'
import Loading from '../../components/Loading';
import { useOrderContext } from '../../context/OrderContext';

function OrderProductDetail() {

    const {isLoading, data, cancelPurchase} = useOrderContext();

    const cancelOrder = () => {
        cancelPurchase.mutate({
            orderId : data.order_id, 
            amount : data.price_sell
        });
    }

    if(isLoading==true){ return (<Loading/>)}
    return (
        <section>
                <h3 className={styles.h3}>상품내역(주문번호: {data.order_id} )</h3>
                <table className='order-info-table'>
                    <thead>
                        <tr>
                            <th>상품정보</th>
                            <th>수량</th>
                            <th>배송비</th>
                            <th className={styles.spec}>배송상태</th>
                            {data.code == 10 ? <th></th> : null}
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td className={styles.width1}>
                                <div className={styles.item_info_container}>
                                    <img src={data.thm_path} alt="thm-image" />
                                    <div className={styles.text_box}>
                                        <span className={styles.name}>{data.item_name}</span>
                                    </div>
                                </div>
                            </td>
                            <td className={styles.width2}>1개</td>
                            <td className={`${styles.width2} ${styles.spec}`}>무료</td>                            
                            <td className={styles.width3}>{data.order_status_name}</td>
                            {data.code == 10 ? (
                            <td className={styles.width4}> <button className={styles.button1} onClick={cancelOrder}>결제취소</button></td>) : null}
                        </tr>
                    </tbody>
                </table>
            </section> 
    );
}

export default OrderProductDetail;