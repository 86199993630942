import React from 'react';
import styles from '../style/orderinfo.module.css'
import Button from '../components/Button';
import Loading from '../components/Loading';
import { useMallContext } from '../context/MallContext';
import {useNavigate } from 'react-router-dom';
import OrderProductDetail from '../components/orderInfo/OrderProductDetail';
import DeliverInfo from '../components/orderInfo/DeliverInfo';
import DeliverTracking from '../components/orderInfo/DeliverTracking';
import {useOrderContext} from '../context/OrderContext';

let style = { width:'30%', margin:'auto', display:'block'}
if (window.innerWidth > 800) {;} else{ style.width = '100%'; }

export default function OrderInfo() {

    const { mall_data } = useMallContext();
    const {isLoading} = useOrderContext();
    
    const navigate = useNavigate();

    if(isLoading == true){
        return (<Loading/>)
    }
    return (
        <div className={styles.order_info_wrapper}>
            <h2 className={styles.h2}>주문내역 확인</h2>
            <OrderProductDetail/>
            <DeliverInfo />
            <DeliverTracking/>
            <Button style={style} text={'목록으로 돌아가기'} onclick={()=>navigate(`${mall_data.path}/list`)}></Button>
        </div>
    );
}

