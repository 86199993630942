import React from 'react';
import {useOrderContext} from '../../context/OrderContext';
import styles from '../../style/orderinfo.module.css'

function DeliverTracking() {

    // const {trackingInfo} = useOrderContext();

    const {isLoading, data} = useOrderContext();

    return (
        data.deliver == true && data.code != 20 ? (
            <section>
                <h3 className={styles.h3}>배송정보</h3>
                <table className='order-info-table ship-info'>
                    <thead>
                        <tr>
                            <th>배송상태</th>
                            <th>위치</th>
                            <th>시간</th>
                        </tr>
                    </thead>
                    <tbody className='ship-info-contents' dangerouslySetInnerHTML={{__html: data.trackingInfo}}>
                    </tbody>
                </table>
            </section>
        ) : null
    );
}

export default DeliverTracking;