import React, { useEffect, useState } from 'react';
import { useOutletContext, useNavigate } from "react-router-dom";
import { useQuery } from '@tanstack/react-query';


export default function useProducts() {

    const {service} = useOutletContext();

    const {isLoading, error, data} = useQuery({
        queryKey : ['products'],
        queryFn : async () => {
            let res = await service.getProducts()
            res.products = res.data.data_item; 
            res.status = res.status;
            return res;
          },
          staleTime: 1000 * 60 * 60 * 3
    })

    return {isLoading, error, data};
}

