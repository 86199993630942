import React from 'react';
import ReactDOM from 'react-dom/client';
import { RouterProvider, createBrowserRouter, useOutletContext, Navigate  } from 'react-router-dom';
import App from './App';
import Layout from './Layout';
import Home from './pages/Home';
import Order from './pages/Order';
import NotFound from './pages/NotFound';
import OrderInfo from './pages/OrderInfo';
import OrderConfirm from './pages/OrderConfirm';
import Login from './pages/Login';
import ProductDetail from './pages/ProductDetail';
import Http from './network/http';
import Service from './service/service';
import ChannelService from './service/channelService';
import Mypage from './pages/Mypage';
import Modal from 'react-modal';
import OrderContextProvider from '../src/context/OrderContext'
import {fetchCsrfToken} from '../src/context/AuthContext'



const baseURL = 'https://api.tempmall.shop'

/* 몰 추가 시 여기값만 추가하면 됨 */
let mall_info = [
  {
    mallPath : '/temp',
    mallId : '10',
    pluginKey : "a2a01c7e-091e-44cd-954e-744a7c11531b",
    type : 'birthday',
    flag : true
  },
  {
    mallPath : '/keis',
    mallId : '14',
    pluginKey : "a2a01c7e-091e-44cd-954e-744a7c11531b",
    type : 'birthday',
    flag : false
  },
  {
    mallPath : '/redcross',
    mallId : '15',
    pluginKey : "a2a01c7e-091e-44cd-954e-744a7c11531b",
    type : 'birthday'
  },
  {
    mallPath : '/infiniq',
    mallId : '16',
    pluginKey : "a84a750d-d510-4f09-96d8-7ae56f8299e4",
    type : 'chuseok',
    flag : true
  },
  {
    mallPath : '/focus_h&s',
    mallId : '17',
    pluginKey : "48d7cdab-3097-4c85-b5ec-0f78a7c46355",
    type : 'chuseok',
    login_type : 'c',
    flag : true
  }
  // {
  //   mallPath : '/hecto_a',
  //   mallId : '12',
  //   pluginKey : "a2a01c7e-091e-44cd-954e-744a7c11531b",
  //   type : 'seolnal'
  // },
  // {
  //   mallPath : '/hecto_b',
  //   mallId : '13',
  //   pluginKey : "a2a01c7e-091e-44cd-954e-744a7c11531b",
  //   type : 'seolnal'
  // }
]

const setRouterData = async () =>{
  let array = [];
  for(let mall of mall_info){
    let mallPath = mall.mallPath;
    let mallId = mall.mallId;
    let pluginKey = mall.pluginKey;
    const client_http = new Http(baseURL, mallPath, ()=>fetchCsrfToken());
    const service = new Service(client_http);
    let channelService = new ChannelService(pluginKey);

    let redirectPath = {
      path:mallPath,
      element:<Navigate to={`${mallPath}/login`} />,
      errorElement:<NotFound/>,
    }
    array.push(redirectPath);
  
    let mainPath = {
      path : mallPath,
      element : <App clientHttp={client_http} service={service} path={mallPath} channelService={channelService} mallId ={mallId}/> ,
      errorElement : <NotFound/>,
      children : [
        {path:'login', element:<Login service={service} type={mall.type} login_type={mall.login_type}/>},
        {
          element: <Layout/>,
          children: [
            {path:'list', element:<Home flag={mall.flag} type={mall.type}/>},
            {path:'product/:id', element:<ProductDetail/>},
            {path:'order/:id', element:<Order/>},
            {path:'order/confirm', element:<OrderConfirm/>},
            {path:'order/info', element:<OrderContextProvider><OrderInfo/></OrderContextProvider>},
            {path:'order/mypage', element:<Mypage/>},
          ],
        }, 
      ]
    }
    let tempPath = {
      path : mallPath,
      element : <App clientHttp={client_http} service={service} path={mallPath} channelService={channelService} mallId ={mallId}/> ,
      errorElement : <NotFound/>,
      children : [
        {path:'login', element:<Login service={service}/>},
        {
          element: <Layout/>,
          children: [
            {path:'list', element:<Home flag={true}/>}
          ],
        }, 
      ]
    }
    // 적십자 임시방편
    if(mallPath == '/redcross'){
      array.push(tempPath);
    }else array.push(mainPath);
    // array.push(mainPath);
  }
  // console.log(array);
  let indexPath = {
    path:'/',
    element:<NotFound/>,
    errorElement:<NotFound/>,
  }
  array.push(indexPath);
  return array;
}

let routerArray = await setRouterData();
const router = createBrowserRouter(routerArray);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  // <React.StrictMode>
     <RouterProvider router={router}/>
  // </React.StrictMode>
);

Modal.setAppElement('#root')