import React from 'react';
import { useMallContext } from '../../context/MallContext';
import styles from '../../style/home.module.css'
import item1 from '../../images/background-item1.png';
import item2 from '../../images/background-item2.png';

export default function MainBanner({flag, type}) {
    
    const {mall_data} = useMallContext();
    // console.log(mall_data.data_mall)
    return (
        <section className={styles.mainbanner}>
            <img className={styles.item1} src={item2} alt="item1" />
            <img className={styles.item2} src={item1} alt="item2" />
            <div className={`${styles.text_box}`}>
                <div className='wrapper'>
                    <div className={styles.text_contents}>
                        { 
                            type == 'seolnal' && <>
                                <h3 className={styles.h3}>{mall_data.data_mall.company_name}</h3> 
                                <span className={styles.span}><b className={styles.b}>임직원을 위한 설날선물</b>과 함께</span>
                                <div className={styles.text_duration}>
                                    <span>신청기간 : <span>01월02일 (수) 14:00 ~ 01월 10일 (수) 17:00</span> </span> 
                                </div> </>
                        }
                        { 
                            type == 'chuseok' && <>
                                <h3 className={styles.h3}>{mall_data.data_mall.company_name}</h3> 
                                <span className={styles.span}><b className={styles.b}>임직원을 위한 추석선물</b>과 함께</span>
                                <div className={styles.text_duration}>
                                    <span>신청기간 : <span>{mall_data.data_mall.open_from} ~ {mall_data.data_mall.open_at}</span> </span> 
                                </div> 
                                <span className={styles.text_min}>*상품은 신청 마감날 또는 그다음날에 일괄 출고됩니다.</span>
                                </>
                        }
                        {
                            type == 'birthday' && <>
                            <span className={styles.span}>{mall_data.data_mall.company_name} 임직원 여러분의 <b className={styles.b}>생일</b>을</span>
                            <span className={styles.span1}>진심으로 축하드립니다.</span>
                            <span className={styles.span}>행복한 하루 보내세요.</span>
                            {
                                flag == true &&
                                <span className={styles.span2}>상품 선택 후, 지사별 담당자에게 취합 부탁드립니다.</span> 
                            }</>
                        }
                    </div>
                    {/* <div className={styles.text_duration}>
                        <span>신청기간 : <span>01월02일 (수) 14:00 ~ 01월 10일 (수) 17:00</span> </span> 
                    </div> */}
                </div>
            </div>
        </section>
    );
}

