import './App.css';
import React from 'react';
import { Outlet} from 'react-router-dom';
import { AuthContextProvider } from './context/AuthContext';
import { MallContextProvider } from './context/MallContext';
import {
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';

const queryClient = new QueryClient()

function App({clientHttp, service, path, channelService, mallId}) {

  console.log(`

  
                                       
      /                     /            
  ---/__----__----__----__-/----__----__-
    /   ) /___) /   ) /   /   /___) /   )
  _/___/_(___ _(___(_(___/___(___ _/___/_
                                     
                                     
                                     
 `);
  // console.log('app.js!');
  channelService.loadScript();
  channelService.boot();
  
  return (
    <>
    <QueryClientProvider client={queryClient}>
      <MallContextProvider service={service} path={path} mallId={mallId}>
        <AuthContextProvider service={service}>
          <Outlet context={{clientHttp, service, path, mallId}}/>
        </AuthContextProvider>
      </MallContextProvider>
      {/* <ReactQueryDevtools initialIsOpen /> */}
    </QueryClientProvider>
    </>
);
}

export default App;
