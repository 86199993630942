import React from 'react';
import Products from '../components/home/Products'
import MainBanner from '../components/home/MainBanner'
import Popup from '../components/modal/Popup'

function Home({flag, type}) {

    return (
        <main>
            <MainBanner flag={flag} type={type}/> {/* 메인베너 */}
            <Products/> {/* 상품 리스트 */}
            {
                flag != true ? <Popup modalIsOpen={true}/> : null
            }
        </main>
    );
}

export default Home;