import Http from '../network/http';
import {fetchCsrfToken} from '../../src/context/AuthContext'

export default class DeliverService {

    constructor(){
        // this.http = new Http('http://localhost:10100', '/carriers')
        this.http = new Http('https://deliver.headen.co.kr', '/carriers', ()=>fetchCsrfToken());
    }

    async getDeliver(deliverKind, invoiceNo){
        return this.http.axios(`/${deliverKind}/tracks/${invoiceNo}` ,{
            method : 'GET'
        })
    }
}

