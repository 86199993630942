import { useImperativeHandle, createRef, createContext, useContext, useEffect, useState, useMemo, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';

const AuthContext = createContext();
const csrfRef = createRef();

export function AuthContextProvider({ children, service }) {

  const [user, setUser] = useState();
  const [csrfToken, setCsrfToken] = useState();
  const navigate = useNavigate();

  useImperativeHandle(csrfRef, () => csrfToken);

  useEffect(()=>{
    service.csrfToken()
    .then((res)=>{setCsrfToken(res);})
    .catch(console.error);
  }, [service]);

  const Login = useCallback(
    async(account, password, path) => {
      service.login(account, password, path)
      .then((response) => {
        if(response.status == 200){
            console.log('login success!');
            setUser(response.data.user);
            navigate(`${path}/list`);
          }else if(response.status == 201){
            alert("사번과 비밀번호(생년월일)를 다시 한 번 확인해주세요.");
          }
        })
      .catch((error)=>{ console.log(error); })
    },[service]
  )
  
  const context = useMemo(
    () => ({
      user,
      setUser,
      Login,
    }),
    [user, setUser, Login]
  );

  return (
    <AuthContext.Provider value={ context } >
      {children}
    </AuthContext.Provider>
  );
}

export function useAuthContext() {
  return useContext(AuthContext);
}

export const fetchCsrfToken = () => csrfRef.current;