import React from 'react';
import { Link , useNavigate} from 'react-router-dom';
import styles from '../style/nav.module.css';
import { PiNotePencilBold } from "react-icons/pi";
import { useMallContext } from '../context/MallContext';
import { useAuthContext } from '../context/AuthContext';

export default function Navbar({service}) {
    const navigate = useNavigate();
    const {mall_data} = useMallContext();
    const {setUser} = useAuthContext();


    // 주문조회를 클릭 했을때 비교하여 분기 처리
    const checkOrderState = ()=>{
        service.checkOrderState().then((response)=>{
            if(response.status == 201){
                // 주문내역 없음
                return navigate(`${mall_data.path}/order/mypage`)
            }else if (response.status == 200){
                // 주문내역 있음
                return navigate(`${mall_data.path}/order/info`);
            }
        })
    }

    const logout = ()=>{
        service.logout().then((response)=>{
            if(response.status == 200){
                setUser(undefined);
                navigate(`${mall_data.path}/login`);
            }
        })
    }

    return (
        <nav className={styles.nav}>
            <div className={`${styles.container}`+ ' wrapper'}>
                <Link to={`${mall_data.path}/list`}> <img src={mall_data.data_mall.company_logo} alt="logo" className={styles.img}/> </Link>
                <div className={styles.right}>
                    {/* <div className={styles.row}> <GoDatabase/>보유포인트 : <span className={styles.point}>0원</span> </div> */}
                    <button className={styles.row} onClick={checkOrderState}> <PiNotePencilBold/>주문조회</button>
                    <button className={styles.row} onClick={logout}>로그아웃</button>
                </div>
            </div>
        </nav>
    );
}

